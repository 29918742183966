export class PositionComponent {
	id: string;
	isModified: boolean;

	private _x: number;
	public get x(): number {
		return this._x;
	}
	public set x(value: number) {
		if (this._x !== value) {
			this._x = value;
			this.isModified = true;
		}
	}
	private _y: number;
	public get y(): number {
		return this._y;
	}
	public set y(value: number) {
		if (this._y !== value) {
			this._y = value;
			this.isModified = true;
		}
	}

	constructor(id: string, x: number, y: number) {
		this.id = id;
		this.x = x;
		this.y = y;
		this.isModified = true;
	}
}
