import { PositionComponent } from "../components/PositionComponent";
import { SizeComponent } from "../components/SizeComponent";
import { VelocityComponent, VelocityDirection } from '../components/VelocityComponent';
import { GameObject } from "../GameObject";
import { GameObjectType } from "../GameObjectTypeEnum";

export class VelocitySystem {
	private world: GameObject;
	private worldSize: SizeComponent;

	private constraintModifier: number;
	private minWorldX: number;
	private maxWorldX: number;
	private minWorldY: number;
	private maxWorldY: number;

	private positionComponents: { [id: string]: PositionComponent };
	private velocityComponents: { [id: string]: VelocityComponent };
	private sizeComponents: { [id: string]: SizeComponent };

	constructor(world: GameObject, positionComponents: { [id: string]: PositionComponent }, velocityComponents: { [id: string]: VelocityComponent }, sizeComponents: { [id: string]: SizeComponent }) {
		this.world = world;
		this.worldSize = sizeComponents[world.id];
		this.positionComponents = positionComponents;
		this.velocityComponents = velocityComponents;
		this.sizeComponents = sizeComponents;

		this.constraintModifier = this.worldSize.width * .04;
		this.minWorldX = this.constraintModifier;
		this.maxWorldX = this.worldSize.width - this.constraintModifier;
		this.minWorldY = this.constraintModifier;
		this.maxWorldY = this.worldSize.height - this.constraintModifier;
	}

	update(time: Date, lastLoopTime: Date) {
		let timeDeltaMs: number = lastLoopTime ? time.valueOf() - lastLoopTime.valueOf() : 0;

		// handle targets
		let targetRows = this.world.getDescendentByType(GameObjectType.TargetRows);
		let targetVelocity = this.velocityComponents[targetRows.id];
		let changeTargetDirection: boolean = false;
		let leftBoundaryCrossedAmount: number = 0;
		let rightBoundaryCrossedAmount: number = 0;
		targetRows.childObjects.forEach((targetRow) => {
			targetRow.childObjects.forEach((target) => {
				let targetPosition = this.positionComponents[target.id];
				let targetSize = this.sizeComponents[target.id];
				if (targetPosition) {
					let targetPixelsToMove = targetVelocity.dimensionPercentPerSecond * .01 * (timeDeltaMs / 1000) * this.worldSize.width;
					if (targetVelocity.direction === VelocityDirection.Right)
						targetPosition.x = targetPosition.x + targetPixelsToMove;
					else
						targetPosition.x = targetPosition.x - targetPixelsToMove;

					if ((targetPosition.x + targetSize.width) > this.maxWorldX) {
						changeTargetDirection = true;
						rightBoundaryCrossedAmount = targetPosition.x - this.maxWorldX;
					}
					else if (targetPosition.x < this.minWorldX) {
						changeTargetDirection = true;
						leftBoundaryCrossedAmount = this.minWorldX - targetPosition.x;
					}
				}
			});
		});

		// fix bug when too much time has elapsed since last update due to tabbing away
		if (!changeTargetDirection && (leftBoundaryCrossedAmount || rightBoundaryCrossedAmount)) {
			// reposition targets
			targetRows.childObjects.forEach((targetRow) => {
				targetRow.childObjects.forEach((target) => {
					let targetPosition = this.positionComponents[target.id];
					if (targetPosition) {
						if (leftBoundaryCrossedAmount)
							targetPosition.x = targetPosition.x + leftBoundaryCrossedAmount;
						else (rightBoundaryCrossedAmount)
							targetPosition.x = targetPosition.x - rightBoundaryCrossedAmount;
					}
				});
			});

			// remove any in-flight ufos or bombs, they'll be in a weird place
			Object.entries(GameObject.allObjects).forEach(([id, obj]) => {
				if (obj.objectType === GameObjectType.Ufo || obj.objectType === GameObjectType.Bomb)
					obj.toBeDeleted = true;
			});
		}

		if (changeTargetDirection)
			targetVelocity.changeDirection();

		// handle bombs
		let bombs = this.world.getAllDescendentsByType(GameObjectType.Bomb);
		bombs.forEach((bomb) => {
			let bombVelocity = this.velocityComponents[bomb.id];
			let bombPosition = this.positionComponents[bomb.id];
			let bombPixelsToMove = bombVelocity.dimensionPercentPerSecond * .01 * (timeDeltaMs / 1000) * this.worldSize.height;
			bombPosition.y = bombPosition.y + bombPixelsToMove;
		});

		// handle bullets
		let bullets = this.world.getAllDescendentsByType(GameObjectType.Bullet);
		bullets.forEach((bullet) => {
			let bulletVelocity = this.velocityComponents[bullet.id];
			let bulletPosition = this.positionComponents[bullet.id];
			let bulletPixelsToMove = bulletVelocity.dimensionPercentPerSecond * .01 * (timeDeltaMs / 1000) * this.worldSize.height;
			bulletPosition.y = bulletPosition.y - bulletPixelsToMove;
		});

		//handle Ufos
		let ufos = this.world.getAllDescendentsByType(GameObjectType.Ufo);
		ufos.forEach((ufo) => {
			if (ufo.toBeDeleted)
				return;

			let ufoVelocity = this.velocityComponents[ufo.id];
			let ufoPosition = this.positionComponents[ufo.id];
			let ufoPixelsToMove = ufoVelocity.dimensionPercentPerSecond * .01 * (timeDeltaMs / 1000) * this.worldSize.width;
			let ufoSize = this.sizeComponents[ufo.id];

			if (ufoVelocity.direction === VelocityDirection.Right) {
				ufoPosition.x = ufoPosition.x + ufoPixelsToMove;
			}
			else {
				ufoPosition.x = ufoPosition.x - ufoPixelsToMove;
			}

			if (ufoPosition.x < this.minWorldX || ufoPosition.x > this.maxWorldX - (ufoSize.width / 2)) {
				ufo.toBeDeleted = true;
			}
		});

		// handle turret
		let turret = this.world.getDescendentByType(GameObjectType.Turret);
		let turretVelocity = this.velocityComponents[turret.id];
		let turretPosition = this.positionComponents[turret.id];
		let turretSize = this.sizeComponents[turret.id];
		let turretPixelsToMove = turretVelocity.dimensionPercentPerSecond * .01 * (timeDeltaMs / 1000) * this.worldSize.width;

		if (turretVelocity.direction === VelocityDirection.Right) {
			turretPosition.x = Math.min(turretPosition.x + turretPixelsToMove, this.worldSize.width - turretSize.width);
		}
		else if (turretVelocity.direction === VelocityDirection.Left) {
			turretPosition.x = Math.max(turretPosition.x - turretPixelsToMove, 0);
		}
	}
}
