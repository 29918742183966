import { CollisionComponent, CollisionDamageType } from "../components/CollisionComponent";
import { PositionComponent } from "../components/PositionComponent";
import { SizeComponent } from "../components/SizeComponent";
import { VelocityComponent, VelocityDirection } from "../components/VelocityComponent";
import { GameObject } from "../GameObject";
import { GameObjectType } from "../GameObjectTypeEnum";

export class BombSystem {
	private world: GameObject;
	private positionComponents: { [id: string]: PositionComponent };
	private sizeComponents: { [id: string]: SizeComponent };
	private collisionComponents: { [id: string]: CollisionComponent };
	private velocityComponents: { [id: string]: VelocityComponent };
	private nextBombDate: Date;
	private avgSecondBetweenBombs: number = 2;

	constructor(world: GameObject, positionComponents: { [id: string]: PositionComponent }, sizeComponents: { [id: string]: SizeComponent }, collidableComponents: { [id: string]: CollisionComponent }, velocityComponents: { [id: string]: VelocityComponent }) {
		this.world = world;
		this.positionComponents = positionComponents;
		this.sizeComponents = sizeComponents;
		this.collisionComponents = collidableComponents;
		this.velocityComponents = velocityComponents;
		let nextBombDate = new Date();
		nextBombDate.setSeconds(nextBombDate.getSeconds() + this.avgSecondBetweenBombs);
		this.nextBombDate = nextBombDate;
	}

	update(time: Date) {


		if (!this.world.getDescendentByType(GameObjectType.Target))
			return;

		// launch a bomb about once every 5 seconds
		// bombs only come from top row, each target can only have one bomb onscreen at a time
		// we'll make the bomb a child of its target so we can use that data later
		/*
			Steps:
			- identify all targets in top row
			- if not targets, abort
			- find targets with no bombs
			- if no targets abort
			- randomly pick a target and create a bomb if it has been more then random ~5s since last one
		*/
		let topRow: GameObject = this.world.getAllDescendentsByType(GameObjectType.TargetRow).find(row => row["rowNumber"] == 0);
		if (!topRow)
			return;

		let topRowTargets = topRow.getAllDescendentsByType(GameObjectType.Target);
		let topRowTargetsWithNoBombs = topRowTargets.filter(target => !target.getChildByType(GameObjectType.Bomb));
		let candidateTargetCount = topRowTargetsWithNoBombs.length;
		if (candidateTargetCount === 0)
			return;

		if (time < this.nextBombDate)
			return;

		let randomTarget = topRowTargetsWithNoBombs[Math.floor(Math.random() * candidateTargetCount)];
		this.createBomb(randomTarget.id);
		this.nextBombDate = new Date(time.getTime());
		this.nextBombDate.setSeconds(this.nextBombDate.getSeconds() + (this.getRandomIntInclusive(80, this.avgSecondBetweenBombs * 100) / 100));
	}

	getRandomIntInclusive(min, max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
	}

	createBomb(targetId: string) {
		let worldSize = this.sizeComponents[this.world.id];
		let targetPosition = this.positionComponents[targetId];
		let targetSize = this.sizeComponents[targetId];
		let targetObj = GameObject.allObjects[targetId];
		let bomb = targetObj.addChild(GameObjectType.Bomb);
		let bombWidth = worldSize.width * .005;
		let bombHeight = worldSize.width * .02;
		this.collisionComponents[bomb.id] = new CollisionComponent(bomb.id, CollisionDamageType.Full);
		this.positionComponents[bomb.id] = new PositionComponent(bomb.id, targetPosition.x + (targetSize.width / 2) - (bombWidth / 2), targetPosition.y + targetSize.height);
		this.sizeComponents[bomb.id] = new SizeComponent(bomb.id, bombWidth, bombHeight);
		this.velocityComponents[bomb.id] = new VelocityComponent(bomb.id, VelocityDirection.Down, 38);
	}
}



