import { CollisionComponent, CollisionDamageType } from "../components/CollisionComponent";
import { PositionComponent } from "../components/PositionComponent";
import { SizeComponent } from "../components/SizeComponent";
import { VelocityComponent } from "../components/VelocityComponent";
import { GameObject } from "../GameObject";
import { GameObjectType } from "../GameObjectTypeEnum";
import Swal from 'sweetalert2'

export class RenderSystem {
	private world: GameObject;
	private worldSize: SizeComponent;
	private lastScore: number = null;
	private level: number = 1;

	// private frameCount: number = 0;
	// private startTime: Date = new Date();

	private positionComponents: { [id: string]: PositionComponent };
	private sizeComponents: { [id: string]: SizeComponent };
	private collisionComponents: { [id: string]: CollisionComponent };
	private velocityComponents: { [id: string]: VelocityComponent };

	constructor(world: GameObject, positionComponents: { [id: string]: PositionComponent }, sizeComponents: { [id: string]: SizeComponent }, collisionComponents: { [id: string]: CollisionComponent }, velocityComponents: { [id: string]: VelocityComponent }) {
		this.world = world;
		this.worldSize = sizeComponents[world.id];
		this.positionComponents = positionComponents;
		this.sizeComponents = sizeComponents;
		this.collisionComponents = collisionComponents;
		this.velocityComponents = velocityComponents;
	}

	init(): void {
		// create viewport
		let viewportEl = this.getElement('viewport', null, this.worldSize.width, this.worldSize.height, null, null);
		document.getElementsByTagName('body')[0].appendChild(viewportEl);

		// floor
		let floor = this.world.getChildByType(GameObjectType.Floor);
		let floorSize = this.sizeComponents[floor.id];
		let floorPosition = this.positionComponents[floor.id];
		this.getElement('floor', null, floorSize.width, floorSize.height, floorPosition.x, floorPosition.y);

		// ceiling
		let ceiling = this.world.getChildByType(GameObjectType.Ceiling);
		let ceilingSize = this.sizeComponents[ceiling.id];
		let ceilingPosition = this.positionComponents[ceiling.id];
		this.getElement('ceiling', null, ceilingSize.width, ceilingSize.height, ceilingPosition.x, ceilingPosition.y);

		// turret
		let turret = this.world.getChildByType(GameObjectType.Turret);
		let turretPosition = this.positionComponents[turret.id];
		let turretSize = this.sizeComponents[turret.id];
		this.getElement(turret.id, turret.objectType, turretSize.width, turretSize.height, turretPosition.x, turretPosition.y);
	}

	update(time: Date): void {
		// this.frameCount++;

		//handling any pending deletions
		Object.entries(GameObject.allObjects).forEach(([id, obj]) => {
			if (obj.toBeDeleted)
				this.deleteObject(obj);
		});

		// handle position and size changes
		for (const id in this.positionComponents) {
			let obj = GameObject.allObjects[id];
			let typ: string = obj.objectType;
			let pos = this.positionComponents[id];
			let size: SizeComponent = this.sizeComponents[id];

			if (pos.isModified || size.isModified) {
				let className = typ;
				let targetEl = this.getElement(id, className, size.width, size.height, pos.x, pos.y);

				// the size changes on some objects, e.g., bunkers
				if (size.isModified) {
					targetEl.style.width = size.width + 'px';
					targetEl.style.height = size.height + 'px';
					size.isModified = false;
				}

				if (pos.isModified) {
					targetEl.style.left = pos.x + 'px';
					targetEl.style.top = pos.y + 'px';
					pos.isModified = false;
				}
			}
		}

		// handle collisions
		let collidingObjects = Object.fromEntries(Object.entries(this.collisionComponents).filter(([k, v]) => v.isColliding));
		for (const [id, collidable] of Object.entries(collidingObjects)) {
			if (collidable.collisionTakesDamageType === CollisionDamageType.Full) {
				const collidableObj = GameObject.allObjects[collidable.id];
				if (collidableObj.objectType === GameObjectType.Turret) {
					collidable.isColliding = false;
					this.useUpOneLife();
				}
				else {
					let isTarget = collidableObj.objectType === GameObjectType.Target;
					
					// Re-parent any bombs belonging to target to grandparent,
					// or they will no longer be found to get position updated.
					if (isTarget) {
						let bombs = collidableObj.getAllDescendentsByType(GameObjectType.Bomb);
						for (var i = 0; i < bombs.length; i++) {
							bombs[i].makeContainedBy(collidableObj.parentObject);
						}
					}


					this.deleteObject(collidableObj);
					if (isTarget && this.world.getAllDescendentsByType(GameObjectType.Target).length === 0) {
						this.level++;
						this.renderLevel(this.level);
					}
				}
			}
			else if (collidable.collisionTakesDamageType === CollisionDamageType.Partial) {
				collidable.isColliding = false;
				const collidableObj = GameObject.allObjects[collidable.id];
				// slowly reduce bunker size
				if (collidableObj.objectType === GameObjectType.Bunker) {
					const reductionPixels = this.worldSize.width * .01;
					const size = this.sizeComponents[collidable.id];
					let oldWidth = size.width;
					let oldHeight = size.height;
					size.width = Math.floor(size.width - reductionPixels);
					size.height = Math.floor(size.height - reductionPixels);
					if (size.width < reductionPixels || size.height < reductionPixels) {
						this.deleteObject(collidableObj);
					}
					else {
						// reposition object based on new size, centered where old position was
						const position = this.positionComponents[collidable.id];
						position.x += (oldWidth - size.width) / 2;
						position.y += (oldHeight - size.height) / 2;
					}
				}

			}
			else if (collidable.collisionTakesDamageType === CollisionDamageType.None) {
				collidable.isColliding = false;
			}

		};

		//handle score
		if (this.lastScore !== this.world["score"]) {
			let scoreEl = document.getElementById('score');
			scoreEl.innerText = this.world["score"];
			this.lastScore = this.world["score"];
		}


		// var seconds = (time.getTime() - this.startTime.getTime()) / 1000;
		// let fps = this.frameCount / seconds;
		// document.getElementById('fps').innerHTML = Math.round(fps) + '';
	}
	renderLevel(level: number) {
		//throw new Error("Method not implemented.");
	}

	useUpOneLife(): void {
		const lives = document.getElementById('lives');
		if (lives.childNodes.length === 0) {
			Swal.fire({
				title: 'GAME OVER!',
				text: 'Press ISHA BISHA to contine',
				icon: 'error',
				confirmButtonText: 'ISHA BISHA'
			}).then((result) => {
				/* Read more about isConfirmed, isDenied below */
				if (result.isConfirmed) {
					location.reload();
				}
			});
		}
		else {
			lives.removeChild(lives.childNodes[0]);
		}
	}

	deleteObject(obj: GameObject) {
		// remove from components
		delete this.collisionComponents[obj.id];
		delete this.positionComponents[obj.id];
		delete this.sizeComponents[obj.id];
		delete this.velocityComponents[obj.id];

		// remove from DOM
		var el = document.getElementById(obj.id);
		if (el && el.parentNode)
			el.parentNode.removeChild(el);

		// remove from world
		obj.removeItem();
	}

	getElement(id: string, className: string, width: number, height: number, left: number, top: number): HTMLDivElement {
		let el = <HTMLDivElement>document.getElementById(id);
		if (!el) {
			el = document.createElement('div');
			if (id) el.id = id;
			if (className) el.classList.add(className);
			if (width || width === 0) el.style.width = width + 'px';
			if (height || height === 0) el.style.height = height + 'px';
			if (height || left === 0) el.style.left = left + 'px';
			if (height || top === 0) el.style.top = top + 'px';

			if (id != 'viewport') this.getViewportEl().appendChild(el);
		}
		return el;
	}

	getViewportEl(): HTMLDivElement {
		return document.getElementById('viewport') as HTMLDivElement;
	}
}
