import { nanoid } from 'nanoid';

export class Util {
	static pluralize(s: string, n: number) {
		if (!s || n === 1)
			return s;

		return s + 's';
	}

	static newId(length:number = 11) {
		return nanoid(11);
	}
}