import { Util } from "./Util";

import { VelocityComponent, VelocityDirection } from './components/VelocityComponent';
import { PositionComponent } from './components/PositionComponent';
import { SizeComponent } from './components/SizeComponent';
import { CollisionComponent, CollisionDamageType } from "./components/CollisionComponent";

import { VelocitySystem } from './systems/VelocitySystem';
import { RenderSystem } from './systems/RenderSystem';
import { CollisionSystem } from "./systems/CollisionSystem";

import { GameObjectType } from './GameObjectTypeEnum';
import { GameObject } from "./GameObject";
import { UfoSystem } from "./systems/UfoSystem";
import { BombSystem } from "./systems/BombSystem";

/*

GameObject hierarchy:

World
	Ceiling
	Ufos
		Ufo
	Targets
		TargetRow
			Target
	Bunkers
	Gun
	Floor
*/


export class Game {
	world: GameObject;
	worldSize: SizeComponent;
	velocity: VelocitySystem;
	render: RenderSystem;
	collision: CollisionSystem;
	ufo: UfoSystem;
	bomb: BombSystem;

	positionComponents: { [id: string]: PositionComponent } = {};
	velocityComponents: { [id: string]: VelocityComponent } = {};
	sizeComponents: { [id: string]: SizeComponent } = {};
	collisionComponents: { [id: string]: CollisionComponent } = {};

	turretId: string;

	init() {
		this.createWorld();
		this.createEngines();
		this.createEntities();
		this.createTurret();
		this.createLives();
		this.addEventListeners();

		this.render.init();
		this.startGameLoop();
	}

	createLives() {
		const livesCount = 3;
		let width = this.getTargetWidth() * 2.5;
		let height = width;

		for (let i = 0; i < livesCount; i++) {
			let el = <HTMLDivElement>document.createElement('div');
			el.id = 'life-' + i;
			el.classList.add('life');
			document.getElementById('lives').appendChild(el);
			el.style.width = width + 'px';
			el.style.height = height + 'px';
		}
	}

	createWorld() {
		// classic version was 4:3 in portrait mode
		// below numbers were taken from a screenshot
		let logicalWidth: number = 1295;
		let logicalHeight: number = 1110;
		let desiredRatio = logicalWidth / logicalHeight;
		let bodyPadding = 12;

		this.world = new GameObject(GameObjectType.World);
		this.world["score"] = 0; // just do this ad hoc for now

		let width = window.innerWidth - (bodyPadding * 2);
		let height = window.innerHeight - (bodyPadding * 2);
		let effectiveRatio = width / height;
		if (effectiveRatio > desiredRatio)
			width = height * desiredRatio;
		else
			height = width / desiredRatio;

		this.sizeComponents[this.world.id] = new SizeComponent(this.world.id, width, height);
		this.worldSize = this.sizeComponents[this.world.id];

		// create floor and ceiling
		let floorAndCeilingWidth = this.worldSize.width - 2;
		let floorAndCeilingHeight = this.worldSize.height * 0.025;

		let floor = this.world.addChild(GameObjectType.Floor);
		this.sizeComponents[floor.id] = new SizeComponent(floor.id, floorAndCeilingWidth, floorAndCeilingHeight);
		this.positionComponents[floor.id] = new PositionComponent(floor.id, 0, this.worldSize.height - floorAndCeilingHeight);
		this.collisionComponents[floor.id] = new CollisionComponent(floor.id, CollisionDamageType.None);
		this.collisionComponents[floor.id].topBoundaryTweakDimensionPercent = 1.1;

		let ceiling = this.world.addChild(GameObjectType.Ceiling);
		this.sizeComponents[ceiling.id] = new SizeComponent(ceiling.id, floorAndCeilingWidth, floorAndCeilingHeight);
		this.positionComponents[ceiling.id] = new PositionComponent(ceiling.id, 0, 0);
		this.collisionComponents[ceiling.id] = new CollisionComponent(ceiling.id, CollisionDamageType.None);
		this.collisionComponents[ceiling.id].bottomBoundaryTweakDimensionPercent = -2;
	}

	createEngines() {
		this.render = new RenderSystem(this.world, this.positionComponents, this.sizeComponents, this.collisionComponents, this.velocityComponents);
		this.velocity = new VelocitySystem(this.world, this.positionComponents, this.velocityComponents, this.sizeComponents);
		this.collision = new CollisionSystem(this.world, this.positionComponents, this.sizeComponents, this.collisionComponents);
		this.ufo = new UfoSystem(this.world, this.positionComponents, this.sizeComponents, this.collisionComponents, this.velocityComponents);
		this.bomb = new BombSystem(this.world, this.positionComponents, this.sizeComponents, this.collisionComponents, this.velocityComponents);
	}

	createEntities() {
		this.createTargets();
		this.createBunkers();
	}

	addEventListeners() {
		let self = this;

		// arrow keys to navigate
		window.addEventListener('keydown', event => {
			let navKeys = ['a', 'A', 'd', 'D', 'ArrowRight', 'ArrowLeft'];

			// short-circuit if not one of the keys we care about
			if (event.altKey || event.ctrlKey || event.shiftKey || !navKeys.includes(event.key))
				return;

			event.preventDefault();

			let turretVelocity = this.velocityComponents[self.turretId];
			if (event.key === 'd' || event.key === 'D' || event.key === 'ArrowRight') {
				turretVelocity.direction = VelocityDirection.Right;
				turretVelocity.isMoving = true;
			}
			if (event.key === 'a' || event.key === 'A' || event.key === 'ArrowLeft') {
				turretVelocity.direction = VelocityDirection.Left;
				turretVelocity.isMoving = true;
			}
		});

		window.addEventListener('keyup', event => {
			let navKeys = ['a', 'A', 'd', 'D', 'ArrowRight', 'ArrowLeft'];

			// short-circuit if not one of the keys we care about
			if (event.altKey || event.ctrlKey || event.shiftKey || !navKeys.includes(event.key))
				return;

			event.preventDefault();

			let turretVelocity = this.velocityComponents[self.turretId];
			if (event.key === 'd' || event.key === 'D' || event.key === 'ArrowRight') {
				if (turretVelocity.direction === VelocityDirection.Right) {
					turretVelocity.direction = VelocityDirection.None;
					turretVelocity.isMoving = false;
				}
			}
			if (event.key === 'a' || event.key === 'A' || event.key === 'ArrowLeft') {
				if (turretVelocity.direction === VelocityDirection.Left) {
					turretVelocity.direction = VelocityDirection.None;
					turretVelocity.isMoving = false;
				}
			}
		});

		// fire!
		window.addEventListener('keydown', event => {
			let fireKeys = [' '];

			// short-circuit if not one of the keys we care about
			if (event.altKey || event.ctrlKey || event.shiftKey || !fireKeys.includes(event.key))
				return;

			event.preventDefault();

			// only allow one bullet at a time
			if (this.world.getDescendentByType(GameObjectType.Bullet))
				return;

			// create bullet
			let bulletWidth = this.worldSize.height * .01;
			let bulletHeight = bulletWidth * 4.423;
			var bullet = this.world.addChild(GameObjectType.Bullet);

			this.sizeComponents[bullet.id] = new SizeComponent(bullet.id, bulletWidth, bulletHeight);

			let turretSize = this.sizeComponents[this.turretId];
			let x = this.positionComponents[this.turretId].x + (turretSize.width / 2) - (bulletWidth / 2);
			let y = this.positionComponents[this.turretId].y - bulletHeight;
			this.positionComponents[bullet.id] = new PositionComponent(bullet.id, x, y);
			this.velocityComponents[bullet.id] = new VelocityComponent(bullet.id, VelocityDirection.Up, 80);
			this.collisionComponents[bullet.id] = new CollisionComponent(bullet.id, CollisionDamageType.Full);
		});
	}

	createTurret() {
		let width = this.getTargetWidth() * 2.5;
		let height = width;
		let x = (this.worldSize.width / 2) - (width / 2);
		let y = this.worldSize.height - height;
		let turret = this.world.addChild(GameObjectType.Turret);
		this.sizeComponents[turret.id] = new SizeComponent(turret.id, width, height);
		this.positionComponents[turret.id] = new PositionComponent(turret.id, x, y);
		this.velocityComponents[turret.id] = new VelocityComponent(turret.id, VelocityDirection.None, 35)
		this.collisionComponents[turret.id] = new CollisionComponent(turret.id, CollisionDamageType.Full);
		this.turretId = turret.id;
	}

	createTargets() {
		let rows = 5;
		let columns = 11;
		let y = this.worldSize.height * .16;
		let width = this.getTargetWidth();
		let height = width;
		let spacing = width * .6;

		let targetRows = this.world.addChild(GameObjectType.TargetRows);
		let dimensionPercentPerSecond = 2;
		this.velocityComponents[targetRows.id] = new VelocityComponent(targetRows.id, VelocityDirection.Right, dimensionPercentPerSecond);

		for (let i = 0; i < rows; i++) {
			let targetRow = targetRows.addChild(GameObjectType.TargetRow);
			targetRow["rowNumber"] = i; // just use ad hoc prop for now, maybe add a DataComponent later?
			let x = (this.worldSize.width - (width * 11) - (spacing * 10)) / 2;
			for (let j = 0; j < columns; j++) {
				let target = targetRow.addChild(GameObjectType.Target);
				this.sizeComponents[target.id] = new SizeComponent(target.id, width, height);
				this.positionComponents[target.id] = new PositionComponent(target.id, x, y);
				this.collisionComponents[target.id] = new CollisionComponent(target.id, CollisionDamageType.Full);
				this.collisionComponents[target.id].bottomBoundaryTweakDimensionPercent = -2;
				this.collisionComponents[target.id].leftBoundaryTweakDimensionPercent = .5;
				this.collisionComponents[target.id].rightBoundaryTweakDimensionPercent = -.5;
				this.collisionComponents[target.id].score = i === 0 ? 35 : 20;

				x += width * 1.6;
			}
			y += height * 1.8;
		}
	}

	createBunkers() {
		let count = 4;
		let width = this.getTargetWidth() * 3;
		let height = width;
		let spacing = width * .8;
		let x = (this.worldSize.width - (width * 4) - (spacing * 3)) / 2;
		let y = this.worldSize.height * .65;
		for (var i = 0; i < count; i++) {
			let bunker = this.world.addChild(GameObjectType.Bunker);
			this.sizeComponents[bunker.id] = new SizeComponent(bunker.id, width, height);
			this.positionComponents[bunker.id] = new PositionComponent(bunker.id, x, y);
			this.collisionComponents[bunker.id] = new CollisionComponent(bunker.id, CollisionDamageType.Partial);

			x += width + spacing;
		}
	}

	getTargetWidth() {
		return this.worldSize.width / 26;
	}

	startGameLoop() {
		window.requestAnimationFrame(() => this.startGameLoop());
		this.gameLoop(this);
	}

	lastLoopTime: Date = null;
	gameLoop(self: Game) {
		let time = new Date();

		// order is important
		self.ufo.update(time);
		self.velocity.update(time, this.lastLoopTime);
		self.collision.update(time);
		self.bomb.update(time);
		self.render.update(time);
		this.lastLoopTime = time;
	}
}
