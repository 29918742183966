import { Util } from "./util";
import { GameObjectType } from './GameObjectTypeEnum';

export class GameObject {
	objectType: GameObjectType;
	id: string;
	toBeDeleted: boolean;
	parentObject: GameObject = null;
	childObjects: GameObject[] = [];
	static allObjects: { [id: string]: GameObject } = {};

	constructor(typ: GameObjectType) {
		this.objectType = typ;
		this.id = Util.newId();
		this.toBeDeleted = false;

		GameObject.allObjects[this.id] = this;
	}

	// an object can only be contained by one other object
	makeContainedBy(parent: GameObject): void {
		this.makeRelated(parent, this);
	}

	// an object may contain any number of child objects
	makeContains(child: GameObject): void {
		this.makeRelated(this, child);
	}

	// Create a bidirectional relationship so that the parent obj has the child in its 
	// list of child objects, and the child object references the parent object as its parent
	private makeRelated(parent: GameObject, child: GameObject): void {
		if (parent == null)
			throw new Error("parent cannot be null");
		if (child == null)
			throw new Error("child cannot be null");
		if (parent === child)
			throw new Error("Object can not contain itself");

		// remove item from old parent collection
		if (child.parentObject)
			child.parentObject.childObjects = child.parentObject.childObjects.filter(function (o) { return o.id !== child.id; });

		// set new parent for child
		child.parentObject = parent;
		// add child to new parent's childObjects
		if (!parent.childObjects.some(co => co.id === child.id))
			parent.childObjects.push(child);
	}

	addChild(typ: GameObjectType): GameObject {
		if (typ == null)
			throw new Error("type cannot be null");

		let child = new GameObject(typ);
		child.makeContainedBy(this);
		return child;
	}

	// Returns first immediate child that matches
	getChildByType(typ: GameObjectType): GameObject {
		if (typ == null)
			throw new Error("type cannot be null");

		return this.childObjects.find(o => o.objectType === typ);
	}

	// Returns first immediate child that matches
	getChildById(id: string): GameObject {
		if (id == null)
			throw new Error("id cannot be null");

		return this.childObjects.find(o => o.id === id);
	}

	// Returns all immediate children that match
	getAllChildrenByType(typ: GameObjectType): GameObject[] {
		if (typ == null)
			throw new Error("type cannot be null");

		return this.childObjects.filter(o => o.objectType === typ);
	}

	// Returns all descendents that match
	getAllDescendentsByType(typ: GameObjectType): GameObject[] {
		if (typ == null)
			throw new Error("type cannot be null");
		let ret: GameObject[] = [];
		this.findAllNodesByType(this.childObjects, typ, ret);
		return ret;
	}

	// Traverses up until it finds a match, else returns null
	getAncestorByType(typ: GameObjectType): GameObject {
		if (typ == null)
			throw new Error("type cannot be null");

		let el: GameObject = this;
		while ((el = el.parentObject) && !(el.objectType === typ));
		return el;
	}

	// Traverses down until it finds a match, else returns null
	getDescendentByType(typ: GameObjectType): GameObject {
		return this.findNodeByType(this.childObjects, typ);
	}

	// Traverses down until it finds a match, else returns null
	getDescendentById(id: string): GameObject {
		if (id == null)
			throw new Error("id cannot be null");

		let ret: GameObject = this.findNodeById(this.childObjects, id);

		return ret;
	}

	findNodeById(arr: GameObject[], id: string): GameObject {
		for (const node of arr) {
			if (node.id === id)
				return node;

			if (node.childObjects) {
				const child = this.findNodeById(node.childObjects, id);
				if (child)
					return child;
			}
		}
	}

	private findNodeByType(arr: GameObject[], typ: GameObjectType): GameObject {
		for (const node of arr) {
			if (node.objectType === typ)
				return node;

			if (node.childObjects) {
				const child = this.findNodeByType(node.childObjects, typ);
				if (child)
					return child;
			}
		}
	}

	private findAllNodesByType(arr: GameObject[], typ: GameObjectType, ret: GameObject[]): void {
		for (const node of arr) {
			if (node.objectType === typ)
				ret.push(node);

			if (node.childObjects)
				this.findAllNodesByType(node.childObjects, typ, ret);
		}
	}

	removeItem(): void {
		this.parentObject.childObjects = this.parentObject.childObjects.filter(o => o.id !== this.id);
		this.parentObject = null;

		delete GameObject.allObjects[this.id];
	}
}