export enum GameObjectType {
	Bomb = 'bomb',
	Bullet = 'bullet',
	Bunker = 'bunker',
	Bunkers = 'bunkers',
	Ceiling = 'ceiling',
	Floor = 'floor',
	Turret = 'turret',
	Target = 'target',
	TargetRow = 'targetRow',
	TargetRows = 'targetRows',
	Ufo = 'ufo',
	World = 'world'
}