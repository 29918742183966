import { CollisionComponent } from "../components/CollisionComponent";
import { PositionComponent } from "../components/PositionComponent";
import { SizeComponent } from "../components/SizeComponent";
import { GameObject } from "../GameObject";
import { GameObjectType } from "../GameObjectTypeEnum";

export class CollisionSystem {
	private world: GameObject;
	private positionComponents: { [id: string]: PositionComponent };
	private sizeComponents: { [id: string]: SizeComponent };
	private collisionComponents: { [id: string]: CollisionComponent };
	private collisionMatrix: { object1: GameObjectType; object2: GameObjectType; }[] = [
		{ object1: GameObjectType.Bomb, object2: GameObjectType.Bunker },
		{ object1: GameObjectType.Bomb, object2: GameObjectType.Floor },
		{ object1: GameObjectType.Bomb, object2: GameObjectType.Turret },
		{ object1: GameObjectType.Bullet, object2: GameObjectType.Bomb },
		{ object1: GameObjectType.Bullet, object2: GameObjectType.Bunker },
		{ object1: GameObjectType.Bullet, object2: GameObjectType.Ceiling },
		{ object1: GameObjectType.Bullet, object2: GameObjectType.Target },
		{ object1: GameObjectType.Bullet, object2: GameObjectType.Ufo }
	];

	constructor(world: GameObject, positionComponents: { [id: string]: PositionComponent }, sizeComponents: { [id: string]: SizeComponent }, collisionComponents: { [id: string]: CollisionComponent }) {
		this.world = world;
		this.positionComponents = positionComponents;
		this.sizeComponents = sizeComponents;
		this.collisionComponents = collisionComponents;
	}

	update(time: Date) {
		Object.entries(this.collisionComponents).forEach(([id1, value1]) => {
			const projectile = GameObject.allObjects[id1];
			// at least one object in collision must be a bullet or a bomb
			if (projectile.objectType !== GameObjectType.Bullet && projectile.objectType !== GameObjectType.Bomb)
				return;

			Object.entries(this.collisionComponents).forEach(([id2, value2]) => {
				//can't collide with yourself
				if (id1 === id2)
					return;

				const target = GameObject.allObjects[id2];

				// validate objects can collide
				if (!this.isCollisionCandidate(projectile, target))
					return;

				let projectilePosition = this.positionComponents[projectile.id];
				let projectileSize = this.sizeComponents[projectile.id];
				let projectileCollision = this.collisionComponents[projectile.id];

				let targetPosition = this.positionComponents[target.id];
				let targetSize = this.sizeComponents[target.id];
				let targetCollision = this.collisionComponents[target.id];

				if (this.rectanglesIntersect(projectilePosition, projectileSize, targetPosition, targetSize, targetCollision)) {
					projectileCollision.isColliding = true;
					targetCollision.isColliding = true;

					if (targetCollision.score)
						this.world["score"] += targetCollision.score;
				}
			});
		});
	}

	public rectanglesIntersect(positionA: PositionComponent, sizeA: SizeComponent, positionB: PositionComponent, sizeB: SizeComponent, collisionB: CollisionComponent): boolean {
		let worldSize = this.sizeComponents[this.world.id];
		worldSize.width
		worldSize.height
		let minAx: number = positionA.x;
		let minAy: number = positionA.y;
		let maxAx: number = positionA.x + sizeA.width;
		let maxAy: number = positionA.y + sizeA.height;
		let minBx: number = positionB.x + (collisionB.leftBoundaryTweakDimensionPercent * .01 * worldSize.width);
		let minBy: number = positionB.y + (collisionB.topBoundaryTweakDimensionPercent * .01 * worldSize.height);
		let maxBx: number = positionB.x + sizeB.width + (collisionB.rightBoundaryTweakDimensionPercent * .01 * worldSize.width);
		let maxBy: number = positionB.y + sizeB.height + (collisionB.bottomBoundaryTweakDimensionPercent * .01 * worldSize.height);
		return maxAx >= minBx && minAx <= maxBx && minAy <= maxBy && maxAy >= minBy;
	}

	isCollisionCandidate(object1: GameObject, object2: GameObject): boolean {
		//can't collide with same type
		if (object1.objectType === object2.objectType)
			return false;

		for (let i = 0; i < this.collisionMatrix.length; i++) {
			let collisionPair = this.collisionMatrix[i];
			if (((object1.objectType === collisionPair.object1) && (object2.objectType === collisionPair.object2))
				|| ((object1.objectType === collisionPair.object2) && (object2.objectType === collisionPair.object1)))
				return true;
		}
		return false;
	}
}
