export class SizeComponent {
	id: string;
	isModified: boolean;

	private _width: number;
	public get width(): number {
		return this._width;
	}
	public set width(value: number) {
		if (this._width !== value) {
			this._width = value;
			this.isModified = true;
		}
	}
	private _height: number;
	public get height(): number {
		return this._height;
	}
	public set height(value: number) {
		if (this._height !== value) {
			this._height = value;
			this.isModified = true;
		}
	}

	constructor(id: string, width: number, height: number) {
		this.id = id;
		this.width = width;
		this.height = height;
		this.isModified = true;
	}
}
