export class CollisionComponent {
	id: string;
	isColliding: boolean;
	score: number;
	collisionTakesDamageType: CollisionDamageType;
	topBoundaryTweakDimensionPercent: number;
	bottomBoundaryTweakDimensionPercent: number;
	leftBoundaryTweakDimensionPercent: number;
	rightBoundaryTweakDimensionPercent: number;

	constructor(id: string, collisionTakesDamageAmount: CollisionDamageType) {
		this.id = id;
		this.isColliding = false;
		this.collisionTakesDamageType = collisionTakesDamageAmount;
		this.topBoundaryTweakDimensionPercent = 0;
		this.bottomBoundaryTweakDimensionPercent = 0;
		this.leftBoundaryTweakDimensionPercent = 0;
		this.rightBoundaryTweakDimensionPercent = 0;
	}
}

export enum CollisionDamageType {
	None,
	Partial,
	Full
}
