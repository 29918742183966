export class VelocityComponent {
	id: string;
	dimensionPercentPerSecond: number;
	direction: VelocityDirection;
	lastUpdateTime: Date;
	isMoving:boolean;

	constructor(id: string, direction: VelocityDirection, dimensionPercentPerSecond: number) {
		this.id = id;
		this.dimensionPercentPerSecond = dimensionPercentPerSecond;
		this.direction = direction;
		this.isMoving = true;
	}

	changeDirection(): void {
		if (this.direction === VelocityDirection.Right)
			this.direction = VelocityDirection.Left
		else if (this.direction === VelocityDirection.Left)
			this.direction = VelocityDirection.Right
	}
}

export enum VelocityDirection {
	Up, 
	Down,
	Left,
	Right,
	None
}
