import { CollisionComponent, CollisionDamageType } from "../components/CollisionComponent";
import { PositionComponent } from "../components/PositionComponent";
import { SizeComponent } from "../components/SizeComponent";
import { VelocityComponent, VelocityDirection } from "../components/VelocityComponent";
import { GameObject } from "../GameObject";
import { GameObjectType } from "../GameObjectTypeEnum";

export class UfoSystem {
	private world: GameObject;
	private positionComponents: { [id: string]: PositionComponent };
	private sizeComponents: { [id: string]: SizeComponent };
	private collisionComponents: { [id: string]: CollisionComponent };
	private velocityComponents: { [id: string]: VelocityComponent };

	constructor(world: GameObject, positionComponents: { [id: string]: PositionComponent }, sizeComponents: { [id: string]: SizeComponent }, collisionComponents: { [id: string]: CollisionComponent }, velocityComponents: { [id: string]: VelocityComponent }) {
		this.world = world;
		this.positionComponents = positionComponents;
		this.sizeComponents = sizeComponents;
		this.collisionComponents = collisionComponents;
		this.velocityComponents = velocityComponents;
	}

	update(time: Date) {
		// no more than one at a time
		if (this.world.getDescendentByType(GameObjectType.Ufo))
			return;

		// no ufos after level is completed
		if (!this.world.getDescendentByType(GameObjectType.Target))
			return;

		// add a new Ufo every 15s
		if (time.getSeconds() % 15 != 0)
			return;

		let worldSize = this.sizeComponents[this.world.id];
		var ufo = this.world.addChild(GameObjectType.Ufo);
		this.positionComponents[ufo.id] = new PositionComponent(ufo.id, 61, worldSize.height * .05);
		this.sizeComponents[ufo.id] = new SizeComponent(ufo.id, worldSize.width * .07, worldSize.width * .07);
		this.collisionComponents[ufo.id] = new CollisionComponent(ufo.id, CollisionDamageType.Full);
		this.collisionComponents[ufo.id].bottomBoundaryTweakDimensionPercent = -3.4;
		this.collisionComponents[ufo.id].score = 50;

		this.velocityComponents[ufo.id] = new VelocityComponent(ufo.id, VelocityDirection.Right, 13);
	}
}



